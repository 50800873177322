import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    articles:{},
    errors:{}
}

const logAction = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

//INDEX ARTICLE BY USERID
const indexArticleSuccess = (state, action) => {
  const newArticles = {...state.articles};
  const userId = action.payload.userId;
  newArticles[userId] = action.payload.data[userId];
  return updateObject(state, {articles:newArticles});
};
const indexArticleFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

//FETCH
const fetchArticleSuccess = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};
const fetchArticleFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

//CREATE
const createArticleSuccess = (state, action) => {
    const userId = action.payload.userId;
    const allArticles = {...state.articles};
    const userArticles = state.articles[userId];
    const newUserArticles = [...userArticles];
    newUserArticles.push(action.payload.data.article);
    allArticles[userId] = newUserArticles;
    return updateObject(state, {articles:{...allArticles}});
};
const createArticleFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

//UPDATE
const updateArticleSuccess = (state, action) => {
  const userId = action.payload.userId;
  const allArticles = {...state.articles};
  const userArticles = state.articles[userId];
  const newUserArticles = [...userArticles].filter(article => {
      return article._id !== action.payload.data.article._id;
  });
  newUserArticles.push(action.payload.data.article);
  allArticles[userId] = newUserArticles;
  return updateObject(state, {articles:{...allArticles}});
};
const updateArticleFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

//DELETE
const deleteArticleSuccess = (state, action) => {
    const userId = action.payload.userId;
    const allArticles = {...state.articles};
    const userArticles = state.articles[userId];
    const newUserArticles = [...userArticles].filter(article => {
        return article._id !== action.payload.articleId;
    });
   allArticles[userId] = newUserArticles;
   return updateObject(state, {articles:{...allArticles}});
};

const deleteArticleFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

const reducer = (state = initialState, action) => {
  switch (action.type){
    case actionTypes.REQUEST_INDEX_ARTICLE:return logAction(state, action);
    case actionTypes.SUCCESS_INDEX_ARTICLE:return indexArticleSuccess(state, action);
    case actionTypes.FAIL_INDEX_ARTICLE:return indexArticleFail(state, action);

    case actionTypes.REQUEST_FETCH_ARTICLE:return logAction(state, action);
    case actionTypes.SUCCESS_FETCH_ARTICLE:return fetchArticleSuccess(state, action);
    case actionTypes.FAIL_FETCH_ARTICLE:return fetchArticleFail(state, action);

    case actionTypes.REQUEST_CREATE_ARTICLE:return logAction(state, action);
    case actionTypes.SUCCESS_CREATE_ARTICLE:return createArticleSuccess(state, action);
    case actionTypes.FAIL_CREATE_ARTICLE:return createArticleFail(state, action);

    case actionTypes.REQUEST_UPDATE_ARTICLE:return logAction(state, action);
    case actionTypes.SUCCESS_UPDATE_ARTICLE:return updateArticleSuccess(state, action);
    case actionTypes.FAIL_UPDATE_ARTICLE:return updateArticleFail(state, action);

    case actionTypes.REQUEST_DELETE_ARTICLE:return logAction(state, action);
    case actionTypes.SUCCESS_DELETE_ARTICLE:return deleteArticleSuccess(state, action);
    case actionTypes.FAIL_DELETE_ARTICLE:return deleteArticleFail(state, action);

    default:return state;
  }
};

export default reducer;
