
import axios from 'axios';

//SUBMIT CONTACT REQUEST
export async function submitContact(data){
    try {
        const request = {
            method:'post',
            url:`/api/contact/`,
            headers:{
                'Accept': 'application/json, text/plain, */*',
				'Content-type':'application/json'},
            data:data
        }
        const response = await axios(request);
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['message']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            error: 'Unsuccessful contact submission - please try again later'
        }
    }
}

export async function indexContact(userId, token){
    try {
        const request = {
            method:'get',
            url:`/api/contact/${userId}`,//This is jinky and nonstandard
            headers:{'x-access-token':token}
        }
        const response = await axios(request);
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}

//DELETE
export async function deleteContact(userId, contactId, token){
    try {
        const request = {
            method:'delete',
            url:`/api/contact/${userId}/${contactId}`,
            headers:{'x-access-token':token}
        }
        const response = await axios(request)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}
