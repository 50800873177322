import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  submitSuccess: false,
  message: "",

  error: false,
  errorMessage: "",
  contacts:[]
}

const logAction = (state, action) => {
  return updateObject(state, {});
};

const submitContactSuccess = (state, action) => {
  return updateObject(state, { submitSuccess: true, message: action.payload.data.message, error: false, errorMessage: "" });
};
const submitContactFail = (state, action) => {
  return updateObject(state, { error: true, errorMessage: action.payload.error });
};

const indexContactSuccess = (state, action) => {
  const newContacts = [ ...action.payload.data.contacts ];
  return updateObject(state, { contacts: newContacts });
};
const indexContactFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

const deleteContactSuccess = (state, action) => {
  const newContacts = state.contacts.filter(contact => {
    return contact._id !== action.payload.contactId;
  });

  return updateObject(state, { contacts: [ ...newContacts ] });
};

const deleteContactFail = (state, action) => {
  console.log(action);
  return updateObject(state, {});
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_SUBMIT_CONTACT: return logAction(state, action);
    case actionTypes.SUCCESS_SUBMIT_CONTACT: return submitContactSuccess(state, action);
    case actionTypes.FAIL_SUBMIT_CONTACT: return submitContactFail(state, action);

    case actionTypes.REQUEST_INDEX_CONTACT: return logAction(state, action);
    case actionTypes.SUCCESS_INDEX_CONTACT: return indexContactSuccess(state, action);
    case actionTypes.FAIL_INDEX_CONTACT: return indexContactFail(state, action);

    case actionTypes.REQUEST_DELETE_CONTACT: return logAction(state, action);
    case actionTypes.SUCCESS_DELETE_CONTACT: return deleteContactSuccess(state, action);
    case actionTypes.FAIL_DELETE_CONTACT: return deleteContactFail(state, action);

    default: return state;
  }
};

export default reducer;
