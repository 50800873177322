import BackgroundImage from '../../../../assets/consoles_full_size_1600.jpeg';

export const styles = theme => ({
    articleWrapper:{
        'background-image': `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${BackgroundImage})`,
        'min-height': '100vh',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        position: 'relative',
        'border-bottom': '1px solid #73cec0'
    },
    articleBody: {
        'text-align': 'center',
        position: 'absolute',
        'height':'100vh',
        padding:'10px 50px 0px 50px',
        'overflow-y':'auto'
    },
    paperContainer:{
        padding:"20px 20px",
    },
    button:{
        color:'#73cec0'
    },
    articleHeader:{
        'margin-bottom':'0px'
    },
    articleTextBox:{
        'font-family':"'Exo 2', sans-serif"
    }
});
