export const styles = theme => ({
  root: {
    width: '100%',
  },
  messageHeading: {

  },
  messageBody: {

  },
  menuButton: {
    color:'white',
    float:'right',
    border:'1px solid white',
    marginRight:'5px'
  }
});
