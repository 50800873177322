import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ArticleList from './ArticleList';
import ArticleForm from './ArticleForm';


class Articles extends Component {
    state ={
        editArticleId:null,
        editArticle:{},
        listLoading:true
    }

    componentDidMount = () => {
        this.props.indexArticle(this.props.auth.userId);
    }

    clearArticleForm = () => {
        this.setState({editArticleId:null, editArticle:{}});
    }

    handleEditClick = (articleId) => {
        const article = this.props.article.articles[this.props.auth.userId].find(article => {
            return article._id === articleId;
        })
        this.setState({editArticleId:articleId, editArticle:article});
    }

    handleDeleteClick = (articleId) => {
        this.props.deleteArticle(this.props.auth.userId, articleId, this.props.auth.token);
        if(articleId === this.state.editArticleId){
            this.clearArticleForm();
        }
    }

    render() {
        let userArticleList;
        const articleList = this.props.article.articles;
        if(articleList){
            userArticleList = articleList[this.props.auth.userId];
        }

        return (
            <Paper>
                <Grid container justify="center" spacing={6}>
                    <Grid item style={{textAlign:'center'}} xs={6}>
                        <Typography variant="h4">
                            Articles
                        </Typography>
                        {userArticleList && (
                            <ArticleList
                                handleEditClick={this.handleEditClick}
                                handleDeleteClick={this.handleDeleteClick}
                                articles={userArticleList}/>
                        )}
                    </Grid>
                    <Grid item style={{textAlign:'center'}} xs={6}>
                        <Typography variant="h4">
                            Article Panel
                        </Typography>
                        <ArticleForm
                            user={this.props.auth}
                            editArticleId={this.state.editArticleId}
                            editArticle={this.state.editArticle}
                            createArticle={this.props.createArticle}
                            updateArticle={this.props.updateArticle}
                            clearArticleForm={this.clearArticleForm}
                            />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Articles);
