import React, { Component } from 'react';

import Banner from './Banner'
import Services from './Services'
import Technologies from './Technologies'
import Products from './Products'
import ContactForm from './ContactForm'


class Home extends Component {
    render() {
        const { config } = this.props;

        return (
            <React.Fragment>
                <Banner config={config}/>
                <Services config={config}/>
                <Technologies config={config}/>
                <Products config={config}/>
                <ContactForm config={config}/>
            </React.Fragment>
        );
    }
}

export default Home;
