
import axios from 'axios';

//ALLBYUSER
export async function indexArticle(userId){
    try {
        const response = await axios.get(`/api/user/${userId}/article/`)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}

//FETCH
export async function fetchArticle(userId, articleId){
    try {
        const response = await axios.get(`/api/user/${userId}/article/${articleId}`)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}

//CREATE
export async function createArticle(userId, data, token){
    try {
        const request = {
            method:'post',
            url:`/api/user/${userId}/article/`,
            headers:{'x-access-token':token},
            data:data
        }
        const response = await axios(request);
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}

//UPDATE
export async function updateArticle(userId, articleId, data, token){
    try {
        const request = {
            method:'put',
            url:`/api/user/${userId}/article/${articleId}`,
            headers:{'x-access-token':token},
            data:data
        }
        console.log(request);
        const response = await axios(request)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}

//DELETE
export async function deleteArticle(userId, articleId, token){
    try {
        const request = {
            method:'delete',
            url:`/api/user/${userId}/article/${articleId}`,
            headers:{'x-access-token':token}
        }
        const response = await axios(request)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}
