import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';   

import testing from '../../../../assets/testing.svg'
import consultation from '../../../../assets/programming.svg'
import development from '../../../../assets/coding.svg'
import courses from '../../../../assets/checklist.svg'
import automation from '../../../../assets/automation.svg'
import ai from '../../../../assets/ai.svg'

import {content} from '../../content.js';
import ServiceCard from './ServiceCard';

class Services extends Component {
    render() {
        const { classes, config } = this.props;
        const { language } = config;
        const sectionContent = content.services[language];

        return (
            <div className={classes.root}>
                <Grid id="services" className={classes.container} container justify="center" spacing={4}>
                    <Grid item style={{textAlign:'center'}} xs={12}>
                        <Hidden xsDown>
                            <Typography gutterBottom variant="h2" color='textPrimary'>
                                {sectionContent['heading']}
                            </Typography>
                            <Typography className={classes.subheader} variant="h4">
                                {sectionContent['subheading']}
                            </Typography>
                        </Hidden>
                        <Hidden smUp>
                            <Typography gutterBottom variant="h4" color='textPrimary'>
                                {sectionContent['heading']}
                            </Typography>
                            <Typography className={classes.subheader} variant="h6">
                                {sectionContent['subheading']}
                            </Typography>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className={classes.container} container justify="center" spacing={6}>
                            <Grid className={classes.gridItem} item xs={12} md={6} lg={4}>
                                <ServiceCard
                                    image={development}
                                    imageTitle={sectionContent.developmentHeading}
                                    title={sectionContent.developmentHeading}
                                    description={sectionContent.development}
                                    />
                            </Grid>
                            <Grid className={classes.gridItem} item xs={12} md={6} lg={4}>
                                <ServiceCard
                                    image={testing}
                                    imageTitle={sectionContent.testingHeading}
                                    title={sectionContent.testingHeading}
                                    description={sectionContent.testing}
                                    />
                            </Grid>
                            <Grid className={classes.gridItem} item xs={12} md={6} lg={4}>
                                <ServiceCard
                                    image={consultation}
                                    imageTitle={sectionContent.consultationHeading}
                                    title={sectionContent.consultationHeading}
                                    description={sectionContent.consultation}
                                    />
                            </Grid>

                            <Grid className={classes.gridItem} item xs={12} md={6} lg={4}>
                                <ServiceCard
                                    image={ai}
                                    imageTitle={sectionContent.aIConsultationHeading}
                                    title={sectionContent.aIConsultationHeading}
                                    description={sectionContent.aIConsultation}
                                    />
                            </Grid>
                            <Grid className={classes.gridItem} item xs={12} md={6} lg={4}>
                                <ServiceCard
                                    image={automation}
                                    imageTitle={sectionContent.automationConsultationHeading}
                                    title={sectionContent.automationConsultationHeading}
                                    description={sectionContent.automationConsultation}
                                    />
                            </Grid>
                            <Grid className={classes.gridItem} item xs={12} md={6} lg={4}>
                                <ServiceCard
                                    image={courses}
                                    imageTitle={sectionContent.technicalTrainingAndCourseDesignHeading}
                                    title={sectionContent.technicalTrainingAndCourseDesignHeading}
                                    description={sectionContent.technicalTrainingAndCourseDesign}
                                    />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Services);
