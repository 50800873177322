//Home Container
import { connect } from 'react-redux';

//Import Component to Wrap
import AuthForm from './AuthForm';

//Import Actions
import { signinUser, signupUser, signoutUser } from '../../../store/actions/auth'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        signinUser: (credentials) => {
            dispatch(signinUser(credentials));
        },
        signupUser: (credentials) => {
            dispatch(signupUser(credentials));
        },
        signoutUser: () => {
            dispatch(signoutUser());
        },
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthForm)
