export const styles = theme => ({
    root: {
        'background-color':theme.palette.primary.main,
        'padding':'50px 50px',
        'border-bottom': '1px solid #73cec0',
    },
    container:{
        'min-height':"410px",
        'padding':'50px 10px'
    },
    [theme.breakpoints.down('xs')]: {
        root:{
            'padding':'25px 0px'
        },
        container:{
            'padding':'25px 10px'
        },
    },
    subheader: {
        'color':'#73cec0',
        'font-style':'italic'
    },
    listText: {
        'color':'#73cec0'
    },
    listItem:{
        'paddingTop':'5px'
    },
    textContainer: {
        'padding':'15px',
        height:'100%'
    },
    checkCircleIcon: {
        'marginRight':'5px',
        'color':'#73cec0'
    },
    thumbnail: {
        'height:':'200px',
        'width':'100%',
        'border':'1px solid #73cec0',
        'cursor':'pointer'
    },
    thumbnailContainer: {
        
    },
    greenText: {
        'color':'#73cec0',
    },
    imagesContainer: {
        padding:'20px'
    },
    modalImage: {
        width:'80vw',
        'border':'1px solid #73cec0',
        margin:'10px'
    },
    modalContainer: {
        height:'100vh'
    },
    modalText:{
        'color':'#73cec0',
        'margin':'20px',
        maxWidth:'78vw'
    }
});