import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Articles from './Articles';
import Contacts from './Contacts';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class Dashboard extends Component {
    state = {
        value: 0,
      };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={this.handleChange}>
            <Tab label="Articles" />
            <Tab label="Contacts" />
          </Tabs>
        </AppBar>
        {value === 0 &&
            <TabContainer> <Articles /> </TabContainer>}
        {value === 1 &&
            <TabContainer> <Contacts /> </TabContainer>}
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
