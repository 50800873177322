export const styles = theme => ({
  root: {
        flexGrow: 1,
        'background-image': 'linear-gradient(rgba(0, 0, 0, 0.275), rgba(0, 0, 0, 0.275)),url("https://s3.amazonaws.com/bcf-web-dev/consoles_full_size_1600.jpg")',
        /* Set a specific height */
        height: '100vh',
        /* Position and center the image to scale nicely on all screens */
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        position: 'relative',
        'border-bottom': '1px solid #73cec0',
      },
    });
