export const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        marginTop: 'auto'
    },
    menuButton: {
        color: 'white',
        float: 'right',
        border: '1px solid white',
        marginRight: '5px'
    },
    articleBody: {
        'text-align': 'left',
        'height': '50vh',
        padding: '10px 50px 0px 50px',
        'overflow-y': 'auto',
        'background-color':'#383838',
        'width':'%100'
    },
});
