import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/ContactListStyles';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';


function ContactList(props) {
  const { classes } = props;
  const contacts = props.contacts.map((contact, key) => {
    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.messageHeading}>
          Name: {contact.name} - Email: {contact.email} {contact.email_sent ? '- Email Sent' : ''}
          <Button onClick={() => props.handleDeleteClick(contact._id)} className={classes.menuButton} aria-label="Delete">
            Delete
          </Button>
        </Typography>
        <Typography className={classes.messageBody}>
          {contact.message}
        </Typography>
      </React.Fragment>
    );
  })
  return (
    <div className={classes.root}>
      {contacts}
    </div>
  );
}


export default withStyles(styles)(ContactList);
