//Blog Container
import { connect } from 'react-redux';

//Import Component to Wrap
import Articles from './Articles';

//Import Actions
import { indexArticle } from '../../store/actions/article'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        auth:state.auth,
        article:state.article
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        indexArticle: (userId) => {
            dispatch(indexArticle(userId));
        },
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Articles)
