export const styles = theme => ({
    root: {
        'flex-grow':1,
        'padding':'20px 50px',
        'background-color':theme.palette.primary.main,
        'border-top': '1px solid #73cec0',
    },
    [theme.breakpoints.down('xs')]: {
        root:{
            'padding':'25px 0px'
        }
    },
    container:{
        'padding':'0px 10px'
    },

    form:{
        'background-color':theme.palette.primary.light,
        'padding':'10px 10px',
        minHeight:'325px'
    },
    formWrapper:{
        'background-color':theme.palette.primary.main,
        'padding':'10px 10px',
    },
    contactInfo:{
        'background-color':theme.palette.primary.light,
        'padding':'10px 10px',
        minHeight:'325px'
    },
    contactInfoWrapper:{
        'background-color':theme.palette.primary.main,
        'padding':'10px 10px',
    },
    errorMessage:{
        'color':theme.palette.error.main
    },
    submitButtonsWapper:{
        marginTop:'20px'
    },
    submitGridItem:{
        textAlign:'center'
    },
    fieldsHeaderSuccess:{
        'color':'#4BB543'
    }
});
