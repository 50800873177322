import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

//Hardcoded for
class TextFieldWrapper extends Component {
    styles = theme => ({
          content:{
          },
          cssTextInput: {
            color:this.props.textColor,
            '&:hover': {
              color: this.props.textColorOnHover,
            },
          },
          underline: {
            '&:hover':{
              borderBottom: this.props.underlineCssOnHover,
            },
            '&:before': {
              borderBottom: this.props.underlineCss,
            },
            '&:after': {
              borderBottom: this.props.underlineCssOnFocused,
            },
          },

          //////
          cssLabel: {
            color: this.props.labelColor,
            '&$cssFocused': {
              color: this.props.labelColorOnFocused,
            },
          },
          cssFocused: {},
    });

  Wrapper = props => (
      <div className={props.classes.content}>
          <TextField
              disabled={this.props.disabled}
              multiline={this.props.multiline}
              fullWidth={this.props.fullWidth}
              onChange={this.UpdateState}
              value={this.props.textFieldValue}
              id={this.props.textFieldId}
              label={this.props.textFieldLabel}
              placeholder={this.props.textFieldPlaceholder}

              InputLabelProps={{
                classes: {
                  root: props.classes.cssLabel,
                  focused: props.classes.cssFocused,
                },
              }}

              InputProps={{
                 classes: {
                   root: props.classes.cssTextInput,
                    underline: props.classes.underline,
                  },
                }}
            />
       </div>
  );

  Styled = withStyles(this.styles)(this.Wrapper);

  UpdateState = (ev)=>{
    this.props.updateTextValue(ev.target.value);
  }

  render(){
      return(
          <this.Styled>{this.props.children}</this.Styled>
      );
  }
}



export default TextFieldWrapper;
