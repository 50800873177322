import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


class LanguageChooser extends Component {
    state = {
        anchorEl: null
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    setLanguage = language => {
        this.props.setConfigValue('language', language);
        this.setState({anchorEl: null});
    };
    
    render() {
        const { classes, config } = this.props;
        const {anchorEl} = this.state;

        return (
            <React.Fragment>
                <Button 
                style={{marginLeft:'auto'}} 
                aria-controls="language-menu" 
                edge="end" 
                aria-haspopup="true" 
                className={classes.menuButton}
                onClick={this.handleClick}>
                    {config?.language?.toUpperCase()}
                </Button>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}>
                    <MenuItem onClick={() => this.setLanguage('en')}>English</MenuItem>
                    <MenuItem onClick={() => this.setLanguage('esp')}>Español</MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(LanguageChooser));
