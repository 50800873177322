export const styles = theme => ({
    root: {
        'background-color':theme.palette.primary.main,
        'padding':'50px 50px',
        'border-bottom': '1px solid #73cec0',
    },
    container:{
        'min-height':"410px",
        'padding':'50px 10px'
    },
    [theme.breakpoints.down('xs')]: {
        root:{
            'padding':'25px 0px'
        },
        container:{
            'padding':'25px 10px'
        },
    },
    subheader: {
        'color':'#73cec0',
        'font-style':'italic'
    },
});
