import React from 'react';
//import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

function ServiceCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <CardActionArea disableRipple={true}>
          <div className={classes.imageWrapper}>
              <img src={props.image} className={classes.image} alt={props.imageTitle}/>
          </div>
        <CardContent className={classes.cardContent}>
          <Typography align={'center'} className={props.header} gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body1">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default withStyles(styles)(ServiceCard);
