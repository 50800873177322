export const content = {
    menuBar: {
        'en': {
            home:"HOME",
            services:"SERVICES",
            technologies:"TECHNOLOGIES",
            products:"PRODUCTS",
            contact:"CONTACT",
            articles:"ARTICLES"
        },
        'esp': {
            home:"HOME",
            services:"SERVICIOS",
            technologies:"TECNOLOGÍAS",
            products:"PRODUCTOS",
            contact:"CONTACTO",
            articles:"ARTÍCULOS"
        }
    },
}
