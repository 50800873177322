import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ContactList from './ContactList';



class Contacts extends Component {
    state = {}

    componentDidMount = () => {
        this.props.indexContact(this.props.auth.userId, this.props.auth.token);
    }

    handleDeleteClick = (contactId) => {
        this.props.deleteContact(this.props.auth.userId, contactId, this.props.auth.token);
    }

    render() {
        const contactList = this.props.contact.contacts;
        return (
            <Paper>
                <Grid container justify="center" spacing={12}>
                    <Grid item style={{textAlign:'left', paddingLeft:'5px'}} xs={12}>
                        <Typography variant="h4" style={{textDecoration:'underline'}}>
                            Contact Requests
                        </Typography>
                        {contactList && (
                            <ContactList
                                handleDeleteClick={this.handleDeleteClick}
                                contacts={contactList}/>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Contacts);
