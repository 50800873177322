import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
  isLoggedIn:false
}

const authStart = (state, action) => {
  return updateObject(state, {error:null, loading: true});
};

const authSuccess = (state, action) => {
  return updateObject(state, {token:action.payload.data.token, userId: action.payload.data.userId, error:null, loading: false, isLoggedIn:true});
};

const authFail = (state, action) => {
  return updateObject(state, {error:action.error, loading: false});
};

const authLogout = (state, action) => {
  return updateObject(state, {token:null, userId: null, isLoggedIn:false});
};

//TODO - To avoid ugly redirect to front page
// const setAuthRedirectPath = (state, action) => {
//   return updateObject(state, {authRedirectPath: action.path});
// };

const reducer = (state = initialState, action) => {
  switch (action.type){
    case actionTypes.REQUEST_SIGNIN_USER:return authStart(state, action);
    case actionTypes.REQUEST_SIGNUP_USER:return authStart(state, action);
    case actionTypes.SUCCESS_SIGNIN_USER:return authSuccess(state, action);
    case actionTypes.SUCCESS_SIGNUP_USER:return authSuccess(state, action);
    case actionTypes.FAIL_SIGNIN_USER:return authFail(state, action);
    case actionTypes.FAIL_SIGNUP_USER:return authFail(state, action);
    case actionTypes.SIGNOUT_USER:return authLogout(state, action);
    //case actionTypes.SET_AUTH_REDIRECT_PATH:return setAuthRedirectPath(state, action);
    default:return state;
  }
};

export default reducer;
