import * as actionTypes from './actionTypes';


//SYNC
export const requestSigninUser = () => ({
    type: actionTypes.REQUEST_SIGNIN_USER,
    payload: {}
});
export const successSigninUser = (data) => ({
    type: actionTypes.SUCCESS_SIGNIN_USER,
    payload: {data:data}
});
export const failSigninUser = (error) => ({
    type: actionTypes.FAIL_SIGNIN_USER,
    payload: {error:error}
});

//

export const requestSignupUser = () => ({
    type: actionTypes.REQUEST_SIGNUP_USER,
    payload: {}
});

export const successSignupUser = (data) => ({
    type: actionTypes.SUCCESS_SIGNUP_USER,
    payload: {data:data}
});

export const failSignupUser = (error) => ({
    type: actionTypes.FAIL_SIGNUP_USER,
    payload: {error:error}
});

//

export const checkAuth = () => ({
    type: actionTypes.CHECK_AUTH,
    payload: {}
});

export const signoutUser = () => ({
    type: actionTypes.SIGNOUT_USER,
    payload: {}
});

//ASYNC
export const signinUser = (data) => {
    return (dispatch, getState, api) => {
        dispatch(requestSigninUser(data))
        return api.signin(data)
            .then((response) => {
                if(response.status === 'success'){
                  dispatch(successSigninUser(response.data))
                } else {
                  dispatch(failSigninUser(response.error))
            }
        })
    }
}

export const signupUser = (data) => {
    return (dispatch, getState, api) => {
        dispatch(requestSignupUser(data))
        return api.signup(data)
            .then((response) => {
                if(response.status === 'success'){
                  dispatch(successSignupUser(response.data))
                } else {
                  dispatch(failSignupUser(response.error))
            }
        })
    }
}
