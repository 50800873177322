//Home Container
import { connect } from 'react-redux';

//Import Component to Wrap
import ContactForm from './ContactForm';

//Import Actions
import { submitContact } from '../../../../store/actions/contact'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        contact:state.contact
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitContact: (data) => {
            dispatch(submitContact(data));
        },
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactForm)
