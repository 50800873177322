import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  language:'en'
}

const setConfigValue = (state, action) => {
  const key = action.payload.key;
  const value = action.payload.value;

  const obj = {};
  obj[key] = value;
  return updateObject(state, {...obj});
};

const reducer = (state = initialState, action) => {
  switch (action.type){
    case actionTypes.SET_CONFIG_VALUE:return setConfigValue(state, action);

    default:return state;
  }
};

export default reducer;
