export const styles = theme => ({
    card: {
      width: '%100',
      minHeight:400
    },
    header: {
        color:theme.palette.secondary.main
    },
    image: {
        height:'300px',
        width:'300px'
    },
    imageWrapper: {
        padding:'20px 20px',
        'text-align':'center'
    },
    cardContent:{
        minHeight:'235px'
    },
    [theme.breakpoints.down('xs')]: {
        image: {
            width:'250px'
        },
        card: {
          'margin-bottom':'10px'
        },
    },
    [theme.breakpoints.down('sm')]: {

    },
    [theme.breakpoints.up('md')]: {

    },
    [theme.breakpoints.up('lg')]: {

    },
});
