import BackgroundImage from '../../../../../assets/consoles_full_size_1600.jpeg';

export const styles = theme => ({
    bannerWrapper: {
        'background-image': `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${BackgroundImage})`,
        /* Set a specific height */
        height: '100vh',
        /* Position and center the image to scale nicely on all screens */
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        position: 'relative',
        'border-bottom': '1px solid #73cec0',
    },
    bannerContent: {
        'text-align': 'center',
        position: 'absolute',
        'height': '100vh'
    },
    [theme.breakpoints.down('xs')]: {

    },
    [theme.breakpoints.down('sm')]: {

    },
    [theme.breakpoints.up('md')]: {

    },
    [theme.breakpoints.up('lg')]: {

    },
});
