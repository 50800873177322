import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/ArticleListStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


function ArticleList(props) {
  const { classes } = props;
  const articles = props.articles.map( (article, key) => {
      return (
          <ExpansionPanel key={key}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Button onClick={() => props.handleEditClick(article._id)} className={classes.menuButton} aria-label="Edit">
                Edit
              </Button>
              <Button onClick={() => props.handleDeleteClick(article._id)} className={classes.menuButton} aria-label="Delete">
                Delete
              </Button>
              <Typography className={classes.heading}>{article.title} - {article.description}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <div className={classes.articleBody} dangerouslySetInnerHTML={{ __html: article.body}} />
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
      );
  })
  return (
    <div className={classes.root}>
      {articles}
    </div>
  );
}


export default withStyles(styles)(ArticleList);
