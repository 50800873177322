import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

class Articles extends Component {
    mainAuthorId = "60cce970906dd783d3f0b15b";

    state = {
        articleContent: "",
        articleTitle: "",
        articleSubscription: ""
    }

    componentDidMount = () => {
        this.props.indexArticle(this.mainAuthorId);
        this.setFirstArticle();
    }

    componentDidUpdate = () => {
        if(!this.state.articleContent){
            this.setFirstArticle();
        }
    }

    handleChoice = articleId => {
        const articleBody = this.props.article.articles[this.mainAuthorId].find(article => {
            return article._id === articleId;
        });
        this.setState({ articleContent: articleBody.body, articleTitle: articleBody.title, articleDescription: articleBody.description });
    }

    setFirstArticle = () => {
        if(!this.props.article.articles[this.mainAuthorId]){
            return;
        }
        const articleBody = this.props.article.articles[this.mainAuthorId][0];
        if(articleBody){
            this.setState({ articleContent: articleBody.body, articleTitle: articleBody.title, articleDescription: articleBody.description });
        }
    }

    render() {
        const { classes } = this.props;

        let articles = [];
        if (this.props.article.articles[this.mainAuthorId]) {
            articles = this.props.article.articles[this.mainAuthorId].map((article, index) => {
                return (<ListItem
                    key={index}
                    button
                    className={classes.button}
                    onClick={() => this.handleChoice(article._id)}>
                    <Typography color={'inherit'} className={classes.listItemText} variant="h6">{article.title}</Typography>
                </ListItem >)
            })
        }

        return (
            <div className={classes.articleWrapper}>
                <Grid className={classes.articleBody} container justify="center" spacing={6}>
                    {articles.length > 0 ? (
                        <Grid item style={{ textAlign: 'center' }} xs={12} sm={3}>
                            <Paper className={classes.paperContainer}>
                                <List>
                                    {articles}
                                </List>
                            </Paper>
                        </Grid>
                    ) : ('')}
                    <Grid item style={{ textAlign: 'justify' }} xs={12} sm={9}>
                        <Paper className={classes.paperContainer}>
                            {!this.state.articleContent ?
                                (
                                    <Typography variant="h3">Articles coming soon!</Typography>
                                )
                                :
                                (
                                    <React.Fragment>
                                        <Typography variant="h3">{this.state.articleTitle}</Typography>
                                        <Typography variant="h6">{this.state.articleDescription}</Typography>
                                        <div className={classes.articleTextBox} dangerouslySetInnerHTML={{ __html: this.state.articleContent }} />
                                    </React.Fragment>
                                )
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Articles);
