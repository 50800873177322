import axios from 'axios';

//LOGIN
export async function signin(data){
    try {
        const response = await axios.post('/api/auth/signin', data)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }
        const responseData = await response.data;
        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            payload: {},
            error: err
        }
    }
}

//SIGNUP
export async function signup(data){
    try {
        const response = await axios.post('/api/auth/signup', data)
        if (response.status >= 400) {
            throw new Error("Bad response from server")
        }

        const responseData = await response.data;

        if(responseData['status'] === 'success'){
            return {
                status: 'success',
                data: responseData,
                error: null
            }
        } else {
            return {
                status: 'error',
                error: responseData['error']
            }
        }
    } catch (err) {
        return {
            status: 'error',
            error: err
        }
    }
}
