import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import LanguageChooser from '../../LanguageChooser';
import { content } from '../../content';

class MobileMenuBar extends Component {
    state = {
        open: false
    }

    toggleDrawer = open => {
        this.setState({ open: open });
    };


    render() {
        const { classes, config } = this.props;

        const { language } = config;
        const sectionContent = content.menuBar[language];

        const sideList = (
            <div className={classes.list}>
                <List>
                    <ListItem className={classes.listItem}>
                        <Link
                            scroll={el => el.scrollIntoView({ behavior: "smooth" })}
                            to="/home"
                            className={classes.link}>

                            <Button className={classes.button}>
                                {sectionContent.home}
                            </Button>
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#services"
                            className={classes.link}>
                            <Button className={classes.button}>
                                {sectionContent.services}
                            </Button>
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#technologies"
                            className={classes.link}
                        >
                            <Button className={classes.button}>
                                {sectionContent.technologies}
                        </Button>
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#products"
                            className={classes.link}
                        >
                            <Button className={classes.button}>
                                {sectionContent.products}
                            </Button>
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link
                            scroll={el => el.scrollIntoView({ block: 'start', behavior: "smooth" })}
                            to="/#contact"
                            className={classes.link}>
                            <Button className={classes.button}>
                                {sectionContent.contact}
                            </Button>
                        </Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link
                            to="/articles"
                            className={classes.link}>
                            <Button className={classes.button}>
                                {sectionContent.articles}
                            </Button>
                        </Link>
                    </ListItem>
                </List>
            </div>
        );

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton className={classes.menuButton} aria-label="Dashboard">
                            <MenuIcon onClick={() => this.toggleDrawer(true)} />
                        </IconButton>
                        <LanguageChooser />
                    </Toolbar>
                    <Drawer open={this.state.open} onClose={() => this.toggleDrawer(false)}>
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={() => this.toggleDrawer(false)}
                            onKeyDown={() => this.toggleDrawer(false)}
                        >
                            {sideList}
                        </div>
                    </Drawer>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(MobileMenuBar));
