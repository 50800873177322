export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const isEmpty = input => {
  if (Array.isArray(input)) {
    return input.length === 0;
  }

  return !input || Object.keys(input).length === 0;
}

export const emailIsValid = input => {
    // eslint-disable-next-line
    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = expression.exec(input);
    return valid;
}
