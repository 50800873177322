//Home Container
import { connect } from 'react-redux';

//Import Component to Wrap
import LanguageChooser from './LanguageChooser';

//Import Actions
import { setConfigValue } from '../../../store/actions/config'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setConfigValue: (key, value) => {
            dispatch(setConfigValue(key, value));
        },
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageChooser)
