import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';

//Redux Imports
import { Provider } from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import Thunk from 'redux-thunk';
import { api } from './store/api'

//Theming
import { ThemeProvider} from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

//Main Styles
import {theme} from './MainTheme';

//Reducers
import authReducer from './store/reducers/auth';
import articleReducer from './store/reducers/article';
import userReducer from './store/reducers/user';
import contactReducer from './store/reducers/contact';
import configReducer from './store/reducers/config';

//Careful with this - if extension not enabled it will crash.
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const rootReducer = combineReducers({
  auth: authReducer,
  article: articleReducer,
  user: userReducer,
  contact:contactReducer,
  config:configReducer
});

const store = createStore(
    rootReducer, 
    composeEnhancers(applyMiddleware(Thunk.withExtraArgument(api)))
);

const app = (
    <React.Fragment>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App/>
            </Provider>
        </ThemeProvider>
    </React.Fragment>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
