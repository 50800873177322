import * as Auth from './auth'
import * as Article from './article'
import * as User from './user'
import * as Contact from './contact'

export const api = {
    ...Auth,
    ...Article,
    ...User,
    ...Contact
}
