//Home Container
import { connect } from 'react-redux';

//Import Component to Wrap
import MobileMenuBar from './MobileMenuBar';

//Import Actions
import { signoutUser } from '../../../store/actions/auth'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        config: state.config
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        signoutUser: () => {
            dispatch(signoutUser());
        },
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileMenuBar)
