export const styles = theme => ({
  root: {
    flexGrow: 1,
    'border-bottom': '1px solid #73cec0',
    'border-top': '1px solid #73cec0'
  },
  link:{
    'text-decoration':'none',
    marginLeft: -12,
    marginRight: 20,
  },
  linkText:{
      color:theme.palette.secondary.main
  },
  menuButton:{
      color:theme.palette.secondary.light
  },
  button:{
      color:'#73cec0'
  },
  listItemText:{
      'text-color':'#73cec0'
  },
  flagIcon:{
    'height':'30px',
    'marginRight':'3px'
  }
});
