import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import {content} from '../../content';

class Banner extends Component {
    render() {
        const { classes, config } = this.props;
        const { language } = config;
        const sectionContent = content.banner[language];

        return (
            <div className={classes.bannerWrapper}>
                <Grid id="home"
                    className={classes.bannerContent}
                    container
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12}>
                        <Hidden xsDown>
                            <Typography variant="h1" color='textPrimary'>
                                Atomic Web Services EIRL
                            </Typography>
                            <Typography variant="h3" color='textPrimary'>
                                {sectionContent.subheading}
                            </Typography>
                        </Hidden>
                        <Hidden smUp>
                            <Typography variant="h3" color='textPrimary'>
                                Atomic Web Services EIRL
                            </Typography>
                            <Typography variant="h6" color='textPrimary'>
                                {sectionContent.subheading}
                            </Typography>
                        </Hidden>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Banner);
