import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AuthForm from '../../UI/AuthForm';

//We pass the location as props to make sure a rerender is triggered from navbar
class Auth extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.authWrapper}>
                <Grid id="home"
                    className={classes.authContent}
                    container
                    justify="center"
                    alignItems="center"
                    spacing={6}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={6}>
                            <Paper className={classes.paperContainer}>
                                <Grid item style={{textAlign:'center'}} xs={12}>
                                    <Typography variant="h3">
                                        Sign In
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <AuthForm location={this.props.location} />
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Auth);
