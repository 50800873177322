//Home Container
import { connect } from 'react-redux';

//Import Component to Wrap
import Articles from './Articles';

//Import Actions
import { indexArticle, fetchArticle, createArticle, updateArticle, deleteArticle } from '../../../../store/actions/article'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        article:state.article
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        indexArticle: (userId) => {
            dispatch(indexArticle(userId));
        },
        fetchArticle: (userId,articleId) => {
            dispatch(fetchArticle(userId, articleId));
        },
        createArticle: (userId, data, token) => {
            dispatch(createArticle(userId, data, token));
        },
        updateArticle: (userId, articleId, data, token) => {
            dispatch(updateArticle(userId, articleId, data, token));
        },
        deleteArticle: (userId, articleId, token) => {
            dispatch(deleteArticle(userId, articleId, token));
        }
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Articles)
