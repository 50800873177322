import { createMuiTheme } from '@material-ui/core/styles';

const font = "'Exo 2', sans-serif";
export const theme = createMuiTheme({
    breakpoints: {
            values:{
                xs:0,
                sm:600,
                md:960,
                lg:1280,
                xl:1600
            }
    },
    palette: {
        primary: {
            light: '#383838',
            main: '#121212',
            dark: '#000000',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#73cec0',
            main: '#3f9d90',
            dark: '#006e63',
            contrastText: '#000000',
        },
        error: {
            main: '#ED371A'
        },
        background: {
            paper:"#383838",
            default: '#383838'
        },
        text:{
            primary:'#FFFFFF',
            secondary:'#909090',
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily:font
    },
    overrides:{
    }
});
