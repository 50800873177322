import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './indexStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';

import { List, ListItem } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';

class Services extends Component {
    state = {
        modalOpen: false,
        modalImage: null,
        modalText: ""
    }

    handleOpen = (image, text) => {
        this.setState({
            modalOpen: true,
            modalImage: image,
            modalText: text
        });
    };

    handleClose = () => {
        this.setState({
            modalOpen: false,
            modalImage: null,
            modalText: ""
        });
    };

    //Because hidden isn't working...  TODO: Move to utility
    adjustTextSizeByWidth = (minWidth, aboveSize, belowSize) => {
        const windowWidth = window.innerWidth;
        if(windowWidth < minWidth){
            return belowSize;
        } else {
            return aboveSize;
        }
    }

    render() {
        const { classes } = this.props;

        const modalText = {
            frontPage: "The frontpage can be configured to show any combination of events, articles, and fighters",
            fighter: "All information can be dynamically edited on a fighter, including suspensions",
            events: "Events can be organized and configured",
            fights: "Configure fights and collaborate with regulatory bodies in the backend"
        }

        return (
            <div className={classes.root}>
                <Grid id="products" className={classes.container} container justify="center" spacing={6}>
                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                        <Hidden xsDown>
                            <Typography gutterBottom variant="h2" color='textPrimary'>
                                Products
                            </Typography>
                            <Typography className={classes.subheader} variant="h4">
                                software for specialized applications
                            </Typography>
                        </Hidden>
                        <Hidden smUp>
                            <Typography gutterBottom variant="h4" color='textPrimary'>
                                Products
                            </Typography>
                            <Typography className={classes.subheader} variant="h6">
                                software for specialized applications
                            </Typography>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className={classes.container} container justify="center" spacing={2}>
                            <Grid className={classes.gridItem} item xs={12} md={12} lg={6}>
                                <Paper className={classes.textContainer}>
                                    <Typography gutterBottom variant="h4">
                                        Fight Promoter - A Combat Sports Management App
                                    </Typography>
                                    <Typography gutterBottom variant="h5">
                                        Designed to help promoters create and manage boxing/MMA sports events, the Fight Promoter contains many unique design features:
                                    </Typography>
                                    <List>
                                        <ListItem className={classes.listItem}>
                                            <CheckCircle className={classes.checkCircleIcon} />
                                            <Typography
                                                className={classes.listText}
                                                variant="h6"> Manage Events and Promotions</Typography>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <CheckCircle className={classes.checkCircleIcon} />
                                            <Typography
                                                className={classes.listText}
                                                variant="h6"> Create a database of fighters and fights</Typography>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <CheckCircle className={classes.checkCircleIcon} />
                                            <Typography
                                                className={classes.listText}
                                                variant="h6"> Communicate with regulatory organizations directly in the app</Typography>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <CheckCircle className={classes.checkCircleIcon} />
                                            <Typography
                                                className={classes.listText}
                                                variant="h6"> Manage users and subscriptions</Typography>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <CheckCircle className={classes.checkCircleIcon} />
                                            <Typography
                                                className={classes.listText}
                                                variant="h6"> Let users leave comments, Frontend moderation included</Typography>
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <CheckCircle className={classes.checkCircleIcon} />
                                            <Typography
                                                className={classes.listText}
                                                variant="h6"> Customizable to your needs and wants</Typography>
                                        </ListItem>
                                    </List>
                                    <Typography gutterBottom variant="h5">
                                        Contact us to schedule a demonstration!
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid className={classes.gridItem} item xs={12} md={12} lg={6}>
                                <Paper className={classes.textContainer}>
                                    <Typography gutterBottom variant="h4">
                                        Screenshots
                                    </Typography>
                                    <Typography 
                                    align="center" 
                                    className={classes.greenText} 
                                    gutterBottom 
                                    variant={this.adjustTextSizeByWidth(650, "h5", "body2")}>
                                        {"< Click image to see more >"}
                                    </Typography>
                                    <Grid className={classes.imagesContainer} container justify="center" spacing={2}>
                                        <Grid
                                            onClick={() => this.handleOpen("https://s3.amazonaws.com/bcf-web-dev/frontpagev2.png", modalText.frontPage)}
                                            className={classes.thumbnailContainer} item xs={12} md={6} lg={6}>
                                            <img 
                                                alt="frontpage"
                                                className={classes.thumbnail} src="https://s3.amazonaws.com/bcf-web-dev/frontpagev2.png"></img>
                                        </Grid>
                                        <Grid
                                            onClick={() => this.handleOpen("https://s3.amazonaws.com/bcf-web-dev/edit-fighterv2.png", modalText.fighter)}
                                            className={classes.thumbnailContainer} item xs={12} md={6} lg={6}>
                                            <img 
                                                alt="edit-fighter"
                                                className={classes.thumbnail} src="https://s3.amazonaws.com/bcf-web-dev/edit-fighterv2.png"></img>
                                        </Grid>
                                        <Grid
                                            onClick={() => this.handleOpen("https://s3.amazonaws.com/bcf-web-dev/edit-event.png", modalText.events)}
                                            className={classes.thumbnailContainer} item xs={12} md={6} lg={6}>
                                            <img 
                                                alt="edit-event"
                                                className={classes.thumbnail} src="https://s3.amazonaws.com/bcf-web-dev/edit-event.png"></img>
                                        </Grid>
                                        <Grid
                                            onClick={() => this.handleOpen("https://s3.amazonaws.com/bcf-web-dev/edit-fights.png", modalText.fights)}
                                            className={classes.thumbnailContainer} item xs={12} md={6} lg={6}>
                                            <img 
                                                alt="edit-fights"
                                                className={classes.thumbnail} src="https://s3.amazonaws.com/bcf-web-dev/edit-fights.png"></img>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Modal
                                open={this.state.modalOpen}
                                onClose={this.handleClose}>
                                <Grid
                                    onClick={this.handleClose}
                                    className={classes.modalContainer}
                                    container justify="center" spacing={6}>
                                    <Paper>
                                        <Grid className={classes.modalImageContainer} item xs={12}>
                                            <Typography 
                                                align="center" 
                                                className={classes.modalText} 
                                                gutterBottom 
                                                variant={this.adjustTextSizeByWidth(650, "h5", "body2")}>
                                                {this.state.modalText}
                                            </Typography>
                                        </Grid>
                                        <Grid className={classes.modalImageContainer} item xs={12}>
                                            <img className={classes.modalImage} alt="fight-Promoter" src={this.state.modalImage}></img>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Modal>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Services);