export const styles = theme => ({
    authWrapper:{
        'background-image': 'linear-gradient(rgba(0, 0, 0, 0.275), rgba(0, 0, 0, 0.275)),url("https://s3.amazonaws.com/bcf-web-dev/consoles_full_size_1600.jpg")',
        height: '100vh',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        position: 'relative',
        'border-bottom': '1px solid #73cec0',
    },
    authContent: {
        'text-align': 'center',
        position: 'absolute',
        'height':'100vh',
    },
    paperContainer:{
        padding:"50px 150px"
    }
});
