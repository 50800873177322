import * as actionTypes from './actionTypes';

//SYNC
export const requestSubmitContact = () => ({
    type: actionTypes.REQUEST_SUBMIT_CONTACT,
    payload: {}
});
export const successSubmitContact = (data) => ({
    type: actionTypes.SUCCESS_SUBMIT_CONTACT,
    payload: {data}
});
export const failSubmitContact = (error) => ({
    type: actionTypes.FAIL_SUBMIT_CONTACT,
    payload: {error}
});

export const requestIndexContact = () => ({
    type: actionTypes.REQUEST_INDEX_CONTACT,
    payload: {}
});
export const successIndexContact = (data) => ({
    type: actionTypes.SUCCESS_INDEX_CONTACT,
    payload: {data}
});
export const failIndexContact = (error) => ({
    type: actionTypes.FAIL_INDEX_CONTACT,
    payload: {error}
});

export const requestDeleteContact = (userId, contactId) => ({
    type: actionTypes.REQUEST_DELETE_CONTACT,
    payload: {userId, contactId}
});
export const successDeleteContact = (userId, contactId) => ({
    type: actionTypes.SUCCESS_DELETE_CONTACT,
    payload: {userId, contactId}
});
export const failDeleteContact = (error) => ({
    type: actionTypes.FAIL_DELETE_CONTACT,
    payload: {error}
});

//ASYNC
export const submitContact = (data) => {
    return (dispatch, getState, api) => {
        dispatch(requestSubmitContact())
        return api.submitContact(data)
            .then((response) => {
                if(response.status === 'success'){
                  dispatch(successSubmitContact(response.data))
                } else {
                  dispatch(failSubmitContact(response.error))
            }
        })
    }
}

export const indexContact = (userId, token) => {
    return (dispatch, getState, api) => {
        dispatch(requestIndexContact())
        return api.indexContact(userId, token)
            .then((response) => {
                if(response.status === 'success'){
                  dispatch(successIndexContact(response.data))
                } else {
                  dispatch(failIndexContact(response.error))
            }
        })
    }
}

export const deleteContact = (userId, contactId, token) => {
    return (dispatch, getState, api) => {
        dispatch(requestDeleteContact(contactId));
        return api.deleteContact(userId, contactId, token)
            .then((response) => {
                if(response.status === 'success'){
                  dispatch(successDeleteContact(userId, contactId));
                } else {
                  dispatch(failDeleteContact(response.error));
            }
        })
    }
}
