import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import reactLogo from '../../../../assets/reactLogo.svg';
import materialUiLogo from '../../../../assets/material-ui-logo.png';
import mongoLogo from '../../../../assets/mongodb-logo.png';
import nodeLogo from '../../../../assets/node-js-logo.png';
import wordpressLogo from '../../../../assets/wordpress-logo.png';
import phoenixLogo from '../../../../assets/phoenix-logo.png';
import seleniumLogo from '../../../../assets/selenium.png';
import expressLogo from '../../../../assets/express-logo.png';

import {content} from '../../content';
import TechnologyCard from './TechnologyCard';

class Technologies extends Component {
    render() {
        const { classes, config } = this.props;
        const { language } = config;
        const sectionContent = content.technologies[language];

        return (
            <div className={classes.root}>
                <Grid id="technologies" className={classes.container} container justify="center">
                    <Grid item style={{textAlign:'center'}} xs={12}>
                        <Hidden xsDown>
                            <Typography gutterBottom variant="h2" color='textPrimary'>
                                {sectionContent.heading}
                            </Typography>
                            <Typography gutterBottom className={classes.subheader} variant="h4">
                                {sectionContent.subheading}
                            </Typography>
                        </Hidden>
                        <Hidden smUp>
                            <Typography gutterBottom variant="h4" color='textPrimary'>
                                {sectionContent.heading}
                            </Typography>
                            <Typography gutterBottom className={classes.subheader} variant="h6">
                                {sectionContent.subheading}
                            </Typography>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid className={classes.container} container justify="center" spacing={4}>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={nodeLogo}
                                    imageTitle={""}
                                    title={"Node.js"}
                                    description={sectionContent.node}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={reactLogo}
                                    imageTitle={""}
                                    title={"React"}
                                    description={sectionContent.react}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={expressLogo}
                                    imageTitle={""}
                                    title={"Express"}
                                    description={sectionContent.express}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={mongoLogo}
                                    imageTitle={""}
                                    title={"MongoDB"}
                                    description={sectionContent.mongodb}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={materialUiLogo}
                                    imageTitle={""}
                                    title={"Material UI"}
                                    description={sectionContent.materialui}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={seleniumLogo}
                                    imageTitle={""}
                                    title={"Selenium/Python"}
                                    description={sectionContent.testing}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={wordpressLogo}
                                    imageTitle={""}
                                    title={"Wordpress"}
                                    description={sectionContent.wordpress}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TechnologyCard
                                    image={phoenixLogo}
                                    imageTitle={""}
                                    title={"Elixir/Phoenix"}
                                    description={sectionContent.phoenix}
                                    />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Technologies);
