import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TextFieldWrapper from '../../../../UI/TextFieldWrapper';

import { emailIsValid } from '../../../../../utility';
import {content} from '../../../content';

class ContactForm extends Component {
    state = {
        fields:{
            name:"",
            email:"",
            message:"",
        },
        validationMessage:""
    };

    clearFields = () => {
        this.setState({
            fields:{
                name:"",
                email:"",
                message:""
            },
            validationMessage:""
        });
    }

    handleSubmitClick = () => {
        const { config } = this.props;
        const { language } = config;
        const sectionContent = content.contactForm[language];

        const oneFieldIsEmpty = Object.keys(this.state.fields).some( key => this.state.fields[key] === '');
        const validEmail = emailIsValid(this.state.fields.email);

        if(oneFieldIsEmpty || !validEmail){
            this.setState({validationMessage:sectionContent.validationMessage});
            return;
        }

        const fields ={
            data:{
                name:this.state.fields.name,
                email:this.state.fields.email,
                message:this.state.fields.message
            },
            submitContact:this.props.submitContact,
            clearFields:this.clearFields
        };

        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LeSdX0UAAAAAJSz0F9cClTFhvTvfs9_GKY11Dpm', {action: 'submit_contact'})
            .then(token => {
                fields.data['captcha'] = token;
                fields.submitContact(fields.data);
                fields.clearFields();
            }, fields);
        });
    };

    handleClearFormClick = () => {
        this.clearFields();
    }

    handleChange = (name, value) => {
        const newFields = {...this.state.fields};
        newFields[name] = value;
        this.setState({fields: {...newFields}, validationMessage:""});
    };

    render() {
        const { classes, config } = this.props;
        const { language } = config;
        const sectionContent = content.contactForm[language];

        const submitError = this.props.contact.error;
        const submitSuccess = this.props.contact.submitSuccess;

        const headerMessage = submitSuccess ? sectionContent.successMessage : sectionContent.subheading;
        const errorMessage = submitError ? this.props.contact.error : "";
        const validationMessage = this.state.validationMessage ? this.state.validationMessage : "";

        console.debug(submitSuccess, headerMessage);
        return (
            <div className={classes.root}>
                <Grid id="contact" className={classes.container} container justify="center">
                    <Grid item style={{textAlign:'center'}} xs={12}>
                        <Typography gutterBottom variant="h2" color='textPrimary'>
                            {sectionContent.heading}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.contactInfoWrapper} md={12} lg={6}>
                        <Paper className={classes.contactInfo}>
                            <Typography gutterBottom variant="h6">
                                {sectionContent.paragraphOne}
                            </Typography>
                            <Typography variant="h6">
                                {sectionContent.paragraphTwo}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item className={classes.formWrapper} md={12} lg={6}>
                        <Paper className={classes.form}>
                            <form noValidate autoComplete="off">
                                <Grid container justify="center" spacing={6}>
                                    <Grid item className={classes.item} xs={12}>
                                        {submitSuccess ? (
                                            <Typography className={classes.fieldsHeaderSuccess} variant="h5">
                                            {headerMessage}
                                        </Typography>
                                        ) : (
                                            <Typography variant="h5">
                                            {headerMessage}
                                        </Typography>
                                        )}
                                        <Typography className={classes.errorMessage} variant="h6">
                                            {errorMessage}
                                        </Typography>
                                        <Typography className={classes.errorMessage} variant="h6">
                                            {validationMessage}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.item} xs={12} sm={6}>
                                        <TextFieldWrapper
                                            disabled={submitSuccess}

                                            textFieldLabel={sectionContent.name}
                                            textFieldPlaceholder={sectionContent.name}
                                            textFieldId='standard-textarea'

                                            labelColor='#73cec0'
                                            labelColorOnFocused='#73cec0'
                                            textColor='white'
                                            textColorOnHover='white'

                                            underlineCss="1px solid #73cec0"
                                            underlineCssOnFocused="1px solid #73cec0"
                                            underlineCssOnHover="rgba(0,0,0,0.0)"

                                            textFieldValue={this.state.fields.name}
                                            updateTextValue={(textValue) => {this.handleChange('name', textValue)}}

                                            margin="normal"
                                            fullWidth
                                            />
                                    </Grid>
                                    <Grid item className={classes.item} xs={12} sm={6}>
                                        <TextFieldWrapper
                                            disabled={submitSuccess}

                                            textFieldLabel='Email'
                                            textFieldPlaceholder='Email'
                                            textFieldId='standard-textarea'

                                            labelColor='#73cec0'
                                            labelColorOnFocused='#73cec0'
                                            textColor='white'
                                            textColorOnHover='white'

                                            underlineCss="1px solid #73cec0"
                                            underlineCssOnFocused="1px solid #73cec0"
                                            underlineCssOnHover="rgba(0,0,0,0.0)"

                                            textFieldValue={this.state.fields.email}
                                            updateTextValue={(textValue) => {this.handleChange('email', textValue)}}

                                            margin="normal"
                                            fullWidth
                                            />
                                    </Grid>
                                    <Grid item className={classes.item} xs={12}>
                                        <TextFieldWrapper
                                            disabled={submitSuccess}

                                            textFieldLabel={sectionContent.message}
                                            textFieldPlaceholder={sectionContent.message}
                                            textFieldId='standard-textarea'

                                            labelColor='#73cec0'
                                            labelColorOnFocused='#73cec0'
                                            textColor='white'
                                            textColorOnHover='white'

                                            underlineCss="1px solid #73cec0"
                                            underlineCssOnFocused="1px solid #73cec0"
                                            underlineCssOnHover="rgba(0,0,0,0.0)"

                                            textFieldValue={this.state.fields.message}
                                            updateTextValue={(textValue) => {this.handleChange('message', textValue)}}

                                            margin="normal"
                                            multiline
                                            fullWidth
                                            />
                                    </Grid>
                                </Grid>
                            </form>
                        <Grid container className={classes.submitButtonsWapper} justify="center" spacing={6}>
                            <Grid item className={classes.submitGridItem} xs={6}>
                                <Button
                                    color="secondary"
                                    variant='outlined'
                                    size='large'
                                    onClick={this.handleSubmitClick}>{sectionContent.submit}</Button>
                            </Grid>
                            <Grid item className={classes.submitGridItem} xs={6}>
                                <Button
                                    color="secondary"
                                    variant='outlined'
                                    size='large'
                                    onClick={this.handleClearFormClick}>{sectionContent.clearForm}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    </Grid>
                </Grid>
            </div>

        );
    }
}

export default withStyles(styles)(withRouter(ContactForm));
