//React Imports
import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import {styles} from './AppStyles';

import Hidden from '@material-ui/core/Hidden';

//Main Page Components
import MenuBar from './components/UI/MenuBar/';
import MobileMenuBar from './components/UI/MobileMenuBar/';
import Home from './components/Home/';
import Dashboard from './components/Dashboard/';
import Articles from './components/Articles/';
import Auth from './components/Auth/';
import Footer from './components/UI/Footer/';

class App extends Component {

  render() {
    const { classes } = this.props;

    return (
          <BrowserRouter>
              <div className={classes.root}>
                  <Hidden xsDown>
                      <MenuBar />
                  </Hidden>
                  <Hidden smUp>
                      <MobileMenuBar />
                  </Hidden>
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/" component={Home} />
                  
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/auth/:mode" component={Auth} />
                  <Route path="/articles" component={Articles} />
                  <Footer />
              </div>
          </BrowserRouter>
    );
  }
}

export default withStyles(styles)(App);
