import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles/indexStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'

class AuthForm extends Component {
    state ={
        credentials:{
            username:"",
            email:"",
            password:""
        },
    }

    handleSignupClick = () => {
        this.props.signupUser(this.state.credentials);
    }

    handleSigninClick = () => {
        const data = {
            email:this.state.credentials.email,
            password:this.state.credentials.password
        }
        this.props.signinUser(data);
    }

    handleModeClick = (mode) => {
        this.props.history.push(`/auth/${mode}`);
    }

    handleSignoutClick = () => {
        this.props.signoutUser();
    }

    handleChange = name => event => {
        const newCredentials = {...this.state.credentials};
        newCredentials[name] = event.target.value;
        this.setState({credentials: {...newCredentials}});
    };

    render() {
        const { classes } = this.props;
        const isSignupMode = this.props.match.params.mode === "signup";
        const fieldAlign = isSignupMode ? 4 : 6;
        const isLoggedIn = this.props.auth.isLoggedIn;

        return (
            <React.Fragment>
                {isLoggedIn ? <Redirect to="/dashboard" /> : ""}
                <form noValidate autoComplete="off">
                    <Grid container justify="center" spacing={6}>
                    { isSignupMode ? (
                        <Grid item className={classes.item} xs={fieldAlign}>
                            <TextField
                              id="standard-name"
                              label="Username"
                              value={this.state.credentials.username}
                              onChange={this.handleChange('username')}
                              margin="normal"
                            />
                        </Grid>
                    ) : ("")
                    }
                        <Grid item className={classes.item} xs={fieldAlign}>
                            <TextField
                              id="standard-email"
                              label="E-mail"
                              value={this.state.credentials.email}
                              onChange={this.handleChange('email')}
                              margin="normal"
                            />
                        </Grid>
                        <Grid item className={classes.item} xs={fieldAlign}>
                            <TextField
                              id="standard-password"
                              label="Password"
                              type="password"
                              value={this.state.credentials.password}
                              onChange={this.handleChange('password')}
                              margin="normal"
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid container justify="center" spacing={6}> 
                    <React.Fragment>
                        <Grid item className={classes.item} xs={6}>
                            <Button onClick={this.handleSigninClick}>Sign In</Button>
                        </Grid>
                    </React.Fragment>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(AuthForm));
