export const styles = theme => ({
    card: {
      width:'100%',
      minHeight:'400px'
    },
    image: {
        width:'250px'
    },
    innerWrapper: {
        display:'flex',
        'flex-direction':'column',
        'justify-content':'center',
    },
    outerWrapper:{
        display:'flex',
        'justify-content':'center',
        height:'300px',
        padding:'20px 20px'
    },
    cardContent:{
        minHeight:'250px'
    },
    [theme.breakpoints.only('lg')]: {
        cardContent:{
            minHeight:'325px'
        }
    },
});
