//AUTH ACTION-TYPES
export const REQUEST_SIGNIN_USER = 'REQUEST_SIGNIN_USER';
export const SUCCESS_SIGNIN_USER = 'SUCCESS_SIGNIN_USER';
export const FAIL_SIGNIN_USER    = 'FAIL_SIGNIN_USER';

export const REQUEST_SIGNUP_USER = 'REQUEST_SIGNUP_USER';
export const SUCCESS_SIGNUP_USER = 'SUCCESS_SIGNUP_USER';
export const FAIL_SIGNUP_USER    = 'FAIL_SIGNUP_USER';

export const CHECK_AUTH          = 'CHECK_AUTH';
export const SIGNOUT_USER        = 'SIGNOUT_USER';

//USER

//ARTICLE
export const REQUEST_INDEX_ARTICLE = 'REQUEST_INDEX_ARTICLE';
export const SUCCESS_INDEX_ARTICLE = 'SUCCESS_INDEX_ARTICLE';
export const FAIL_INDEX_ARTICLE    = 'FAIL_INDEX_ARTICLE';

export const REQUEST_FETCH_ARTICLE = 'REQUEST_FETCH_ARTICLE';
export const SUCCESS_FETCH_ARTICLE = 'SUCCESS_FETCH_ARTICLE';
export const FAIL_FETCH_ARTICLE    = 'FAIL_FETCH_ARTICLE';

export const REQUEST_CREATE_ARTICLE = 'REQUEST_CREATE_ARTICLE';
export const SUCCESS_CREATE_ARTICLE = 'SUCCESS_CREATE_ARTICLE';
export const FAIL_CREATE_ARTICLE    = 'FAIL_CREATE_ARTICLE';

export const REQUEST_UPDATE_ARTICLE = 'REQUEST_UPDATE_ARTICLE';
export const SUCCESS_UPDATE_ARTICLE = 'SUCCESS_UPDATE_ARTICLE';
export const FAIL_UPDATE_ARTICLE    = 'FAIL_UPDATE_ARTICLE';

export const REQUEST_DELETE_ARTICLE = 'REQUEST_DELETE_ARTICLE';
export const SUCCESS_DELETE_ARTICLE = 'SUCCESS_DELETE_ARTICLE';
export const FAIL_DELETE_ARTICLE    = 'FAIL_DELETE_ARTICLE';

//CONTACT
export const REQUEST_SUBMIT_CONTACT = 'REQUEST_SUBMIT_CONTACT';
export const SUCCESS_SUBMIT_CONTACT = 'SUCCESS_SUBMIT_CONTACT';
export const FAIL_SUBMIT_CONTACT    = 'FAIL_SUBMIT_CONTACT';

export const REQUEST_INDEX_CONTACT = 'REQUEST_INDEX_CONTACT';
export const SUCCESS_INDEX_CONTACT = 'SUCCESS_INDEX_CONTACT';
export const FAIL_INDEX_CONTACT    = 'FAIL_INDEX_CONTACT';

export const REQUEST_DELETE_CONTACT = 'REQUEST_DELETE_CONTACT';
export const SUCCESS_DELETE_CONTACT = 'SUCCESS_DELETE_CONTACT';
export const FAIL_DELETE_CONTACT    = 'FAIL_DELETE_CONTACT';

//CONFIG
export const SET_CONFIG_VALUE = 'SET_CONFIG_VALUE';