//Home Container
import { connect } from 'react-redux';

//Import Component to Wrap
import Contacts from './Contacts';

//Import Actions
import { indexContact, deleteContact } from '../../../../store/actions/contact'

//Pass Global State to Component
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        contact:state.contact
    }
}

//Pass Actions to Component
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        indexContact: (userId, token) => {
            dispatch(indexContact(userId, token));
        },
        deleteContact: (userId, contactId, token) => {
            dispatch(deleteContact(userId, contactId, token));
        }
    }
}

//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contacts)
